import Async from "react-async";
import axios from "axios";
import { useAuth } from "oidc-react";
import { VegaLite } from "react-vega";
import Select from "react-select";
import { useState, useEffect } from "react";
import "./app.css";

class Solution {
  http;

  constructor(token) {
    const origin = window.origin.includes("localhost")
      ? "https://ctv-geo-insights.ttd-sa-dev.com"
      : window.origin;

    this.http = axios.create({
      baseURL: `${origin}/api`,
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });
  }

  report = async (partner, advertiser, campaign) => {
    return (
      await this.http.get("/report", {
        params: {
          partner: partner,
          advertiser: advertiser,
          campaign: campaign,
        },
      })
    ).data;
  };

  partners = async () => (await this.http.get("/partners")).data;
  advertisers = async (partner) =>
    (await this.http.get("/advertisers", { params: { partner: partner } }))
      .data;
  campaigns = async (partner, advertiser) =>
    (
      await this.http.get("/campaigns", {
        params: { partner: partner, advertiser: advertiser },
      })
    ).data;
}

function SelectOption({ enable, fetch, label, value, set, setError }) {
  const render_option = (p) => ({
    label: p[label],
    value: p[label],
  });

  return enable ? (
    <Async promiseFn={fetch}>
      <Async.Pending>
        <Select isDisabled={true} isLoading={true} />
      </Async.Pending>
      <Async.Rejected>
        {(e) => setError(`${e.message}: ${e.response.data}`)}
      </Async.Rejected>
      <Async.Fulfilled>
        {(options) => (
          <Select
            searchable={true}
            isClearable={true}
            options={options.map(render_option)}
            value={value}
            onChange={set}
          />
        )}
      </Async.Fulfilled>
    </Async>
  ) : (
    <Select isDisabled={true} isLoading={false} />
  );
}

function RelevancePlot({ dataFn }) {
  const spec = {
    width: "container",
    encoding: {
      x: { field: "RELEVANCE", type: "quantitative" },
      y: { field: "SEGMENT", type: "ordinal", sort: "-x" },
    },
    data: { name: "scores" },
    layer: [
      { mark: "bar" },
      {
        mark: {
          type: "text",
          align: { expr: "datum.RELEVANCE < 0 ? 'right' : 'left'" },
          dx: { expr: "datum.RELEVANCE < 0 ? -2 : 2" },
        },
        encoding: {
          text: {
            field: "RELEVANCE",
            type: "quantitative",
            format: ".3f",
          },
        },
      },
    ],
  };

  return (
    <Async promiseFn={dataFn}>
      <Async.Pending>
        <div>Loading...</div>
      </Async.Pending>
      <Async.Rejected>{(e) => e.setError(JSON.stringify(e))}</Async.Rejected>
      <Async.Fulfilled>
        {(data) => <VegaLite spec={spec} data={{ scores: data }} />}
      </Async.Fulfilled>
    </Async>
  );
}

export default function App() {
  const auth = useAuth();
  const solution = new Solution(auth.userData?.id_token);

  const [partner, setPartner] = useState(null);
  const [advertiser, setAdvertiser] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [hasError, setError] = useState(null);

  useEffect(() => setAdvertiser(null), [partner]);
  useEffect(() => setCampaign(null), [partner, advertiser]);

  return (
    <div>
      <h2>CTV Geo-Insights</h2>

      {hasError ? (
        hasError
      ) : (
        <div>
          <label>Partner</label>
          <SelectOption
            enable={true}
            fetch={() => solution.partners()}
            label="PARTNER"
            value={partner}
            set={setPartner}
            setError={setError}
          />
          <label>Advertiser</label>
          <SelectOption
            enable={partner != null}
            fetch={() => solution.advertisers(partner.value)}
            label="ADVERTISER"
            value={advertiser}
            set={setAdvertiser}
            setError={setError}
          />
          <label>Campaign</label>
          <SelectOption
            enable={advertiser != null}
            fetch={() => solution.campaigns(partner.value, advertiser.value)}
            label="CAMPAIGN"
            value={campaign}
            set={setCampaign}
            setError={setError}
          />

          {partner != null && advertiser != null && campaign != null ? (
            <RelevancePlot
              dataFn={async () =>
                await solution.report(
                  partner.value,
                  advertiser.value,
                  campaign.value,
                )
              }
              setError={setError}
            />
          ) : (
            <div>select partner, advertiser and campaign</div>
          )}
        </div>
      )}
    </div>
  );
}
