import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider, useAuth } from "oidc-react";
import "./index.css";
import App from "./app.js";

function LoginContainer({ children }) {
  const auth = useAuth();
  if (auth && auth.userData) {
    console.log(auth);

    return (
      <div>
        <strong>Logged in! 🎉</strong> {auth.userData?.profile.email}
        {children}
      </div>
    );
  }
  return <div>Not logged in! Redirecting to TTD SSO...</div>;
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider
      authority={process.env.REACT_APP_AUTHORITY}
      clientId={process.env.REACT_APP_CLIENT_ID}
      redirectUri={window.origin}
      onSignIn={async (user) => {
        console.log(user);
        window.location.search = "";
      }}
    >
      <LoginContainer>
        <App />
      </LoginContainer>
    </AuthProvider>
  </React.StrictMode>,
);
